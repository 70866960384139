import React from 'react';
import PropTypes from 'prop-types';

const VulnerabilityModal = ({ vulnerability }) => (
  <div className="modal fade" id="vulnarabilityDeatilsModal" tabIndex="-1" aria-labelledby="vulnarabilityDeatilsModal" aria-hidden="true">
    <div className="modal-dialog modal-dialog-scrollable modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="vulnarabilityDeatilsModal">{vulnerability?.vulnerability?.id}</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <dl className="row">
            <dt className="col-sm-3" />
            <dd className="col-sm-9"><h5>Vulnerability</h5></dd>

            <dt className="col-sm-3">Severity</dt>
            <dd className="col-sm-9">{vulnerability?.vulnerability?.severity}</dd>

            { vulnerability?.vulnerability?.description
              ? (
                <>
                  <dt className="col-sm-3">Description</dt>
                  <dd className="col-sm-9">{vulnerability?.vulnerability?.description}</dd>
                </>
              ) : '' }

            { vulnerability?.vulnerability?.links
              ? (
                <>
                  <dt className="col-sm-3">Links</dt>
                  <dd className="col-sm-9">
                    <ul className="ps-3 mb-0">
                      {vulnerability?.vulnerability?.links.map((link) => (<li key={link}><a href={link} target="_blank" rel="noreferrer">{link}</a></li>))}
                    </ul>
                  </dd>
                </>
              ) : '' }

            <hr />

            <dt className="col-sm-3" />
            <dd className="col-sm-9"><h5>Match Details</h5></dd>

            <dt className="col-sm-3">Matcher</dt>
            <dd className="col-sm-9">{vulnerability?.matchDetails?.matcher}</dd>

            <dt className="col-sm-3">Matched On</dt>
            <dd className="col-sm-9"><code>{vulnerability?.matchDetails?.matchedOn?.constraint}</code></dd>

            <hr />

            <dt className="col-sm-3" />
            <dd className="col-sm-9"><h5>Artifact</h5></dd>

            <dt className="col-sm-3">Name</dt>
            <dd className="col-sm-9">{vulnerability?.artifact?.name}</dd>

            <dt className="col-sm-3">Version</dt>
            <dd className="col-sm-9">{vulnerability?.artifact?.version}</dd>

            <dt className="col-sm-3">Type</dt>
            <dd className="col-sm-9">{vulnerability?.artifact?.type}</dd>

            { vulnerability?.artifact?.metadata?.homepage
              ? (
                <>
                  <dt className="col-sm-3">Homepage</dt>
                  <dd className="col-sm-9"><a href={vulnerability?.artifact?.metadata?.homepage} target="_blank" rel="noreferrer">{vulnerability?.artifact?.metadata?.homepage}</a></dd>
                </>
              ) : '' }

            { vulnerability?.artifact?.licenses
              ? (
                <>
                  <dt className="col-sm-3">Licenses</dt>
                  <dd className="col-sm-9">
                    <ul className="ps-3 mb-0">
                      {vulnerability?.artifact?.licenses.map((license) => (
                        <li key={license}>{license}</li>
                      ))}
                    </ul>
                  </dd>
                </>
              ) : '' }

            <dt className="col-sm-3">Locations</dt>
            <dd className="col-sm-9">
              <ul className="ps-3 mb-0">
                {vulnerability?.artifact?.locations.map((location) => (
                  <li key={location.path}>
                    Layer&nbsp;
                    {location?.layerIndex}
                    &nbsp;-&nbsp;
                    <code>{location?.path}</code>
                  </li>
                ))}
              </ul>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
);

VulnerabilityModal.propTypes = {
  vulnerability: PropTypes.objectOf(PropTypes.shape),
};

VulnerabilityModal.defaultProps = {
  vulnerability: {},
};

export default VulnerabilityModal;
